import { get, post } from 'common/request'

export default {

  // 当前项目分页查询
  currentProjectList: args => get('/approve-project/listAll', args),
  // 获取待办列表
  getBackloglist: args => get('/task/userTaskAll', args),
  // 获取已办列表
  finishedFile: args => get('/task/myFinishedTask', args),
  //  代办列表
  myFile: args => get('/task/myTask', args),

  // 项目类型饼图统计
  projectType: args => get('/home/projectType'),
  // 设备类型饼图tongji
  deviceType: args => get('/home/deviceType', args),

  // 巡护情况统计
  patrol: args => get('/home/patrol/statusData', args),

  // 巡护情况统计
  patrolStatus: args => get('/home/patrol/data', args),

  // 巡护情况统计
  patrolPlan: args => get('/home/patrol/plan', args),

  statistics: args => post('/staff/statistics', args),

  getInfoList: args => get('/home/patrol/message', args),

  list: args => get('/task/myPackTask', args),
  // 新闻,公告列表
  queryMessage: args => get('news/queryMessage', args),
  // 新闻列表
  newsList: args => get('/news/home/newsList ', args),
  // 新闻列表
  noticeList: args => get('/announcement/home/noticeList', args),
  // 获取天气
  getWheather: args => get('/weather/get', args),

  // 获取通知公告
  // 分页查询，用户只能查看自己权限内的刚刚
  queryListByUser: args => get('/announcement/home/queryListByUser', args),
  // 获取会议
  getMeeting: args => get('/meeting/list', args),

}
